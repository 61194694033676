input,
select {
	border: 1px solid $blue;
	border-radius: 6px;
	text-align: center;
	color: $grey;
	font-size: 16px;

	&.outlined {
		background-color: transparent;
		color: $blue;
		font-size: 15px;
		font-weight: 500;
	}
}

input:disabled,
select:disabled {
	opacity: 0.5;
}
