select {
	&.global-settings-select {
		border-bottom: 1px solid $blue;
		border-radius: 0px;
		color: $blue;
	}
}

.bottom-line {
	color: $lightGrey;
	font-size: 12px;
}

.blue {
	color: $blue;
}

.bold {
	font-weight: 600;
}
