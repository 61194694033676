.details {
	.details-footer {
		.solution-report-wrapper {
			display: flex;
			flex-grow: 1;
			align-items: center;
			justify-content: space-between;
			padding: 16px 0 0 16px;
		}
	}

	.details-wrapper {
		display: flex;
		flex-direction: column;
		padding: 20px;
		background-color: $darkerWhite;
	}

	.details-chart-container {
		display: flex;
		height: 100%;
		flex-direction: column;

		.resilience-block-selector {
			display: flex;
			color: $darkText;
			font-size: 14px;
			font-weight: bold;
			align-items: center;
			padding: 12px 12px 12px 0;
			margin-left: 0;
			flex-grow: 1;
			select {
				margin-left: 8px;
				margin-right: 8px;
			}

			.select {
				.button-wrapper {
					font-weight: bold;
					color: $darkText !important;
					border-bottom: 1px solid $darkText;
				}
				.option {
					color: $darkText;
					&:hover {
						text-decoration: none;
						background-color: $grey;
						color: $white;
					}
				}
			}

			& > .loading-spinner {
				display: flex;
				align-items: center;
			}
		}

		.selector-confirm {
			padding: 0.25rem 0.5rem;
			font-size: 0.775rem;
			line-height: 1.5;
		}
	}

	.selector {
		text-align: left;
		border: none;
		border-bottom: 1px solid $blue;
		border-radius: 0px;
		color: $blue;
	}

	.clear-button {
		cursor: pointer;
		color: $lightGrey;
		margin-left: 10px;
	}

	.legends-wrapper {
		display: flex;
		align-items: center;
	}

	.btn-confirm {
		height: 20px;
		width: 80px;
		padding: 0 8px;
		font-size: 12px;
	}

	.legend-wrapper {
		display: flex;
		align-items: center;
		margin-right: 8px;

		& > * {
			margin-left: 8px;
		}
	}

	.header-text,
	.legend-text {
		color: $darkText;
		font-size: 14px;
		font-weight: bold;
	}

	.vertical-divider {
		padding-top: 30px;
		padding-bottom: 1px;
		border-left: 1px solid $lightGrey;
		height: inherit;
	}

	.chart-type-select {
		padding: 5px;
		color: $blue;
		background-color: $white;
		font-weight: bold;
	}

	.diamond-small {
		margin: 0 8px 0 8px;
		height: 10px;
		width: 10px;
		background: $blue;
		transform: rotate(45deg);
		-ms-transform: rotate(45deg); /* Internet Explorer */
		-moz-transform: rotate(45deg); /* Firefox */
		-webkit-transform: rotate(45deg); /* Safari et Chrome */
		-o-transform: rotate(45deg); /* Opera */
	}

	.diamond-large {
		margin: 0 8px 0 8px;
		height: 16px;
		width: 16px;
		background: $blue;
		transform: rotate(45deg);
		-ms-transform: rotate(45deg); /* Internet Explorer */
		-moz-transform: rotate(45deg); /* Firefox */
		-webkit-transform: rotate(45deg); /* Safari et Chrome */
		-o-transform: rotate(45deg); /* Opera */
	}

	.square {
		height: 10px;
		width: 10px;
	}

	.details-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}

	.details-header-model-container {
		display: flex;
		font-size: 20px;
		font-weight: bold;
		align-items: center;
	}

	.details-header-model-name {
		color: $blue;
		margin-right: 16px;
		text-transform: capitalize;
	}

	.details-header-actions {
		display: flex;
		& > * {
			margin-left: 16px;
		}
	}

	.details-panel {
		background-color: $white;
		border-radius: 20px;
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		width: 100%;
		height: calc(100vh - 200px);
		display: flex;
		align-items: stretch;
	}

	.details-node-info {
		flex-basis: 300px;
		flex-shrink: 0;
		flex-grow: 0;
		border-right: 1px solid $lightGrey;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		.details-node-header {
			border-bottom: 1px solid $lightGrey;
			padding: 20px;
			max-height: 425px;
			overflow: auto;
		}

		.details-data-wrapper {
			flex-grow: 1;
			overflow: auto;
		}

		.select-info-panel-currency {
			color: $blue;
			max-width: 50px;
			margin-right: 8px;
			border: none;
			text-align-last: right;
			font-size: 14px;
		}

		.model-picture {
			max-width: 250px;
		}

		.model-description {
			font-size: 12px;
			margin-top: -12px;
			color: $lightGrey;
		}

		div.divider {
			width: 100%;
			border-bottom: 1px solid $veryLightGrey;
		}

		div.top-block-counter {
			color: $text;
			margin-top: 10px;
			margin-bottom: 20px;
			.value {
				font-size: 40px;
				text-align: center;
				line-height: 1;
			}

			.sub-title {
				text-transform: uppercase;
				font-weight: 500;
				text-align: center;
				font-size: 14px;
			}
		}

		.data-block-data {
			display: flex;
			align-items: center;
			padding-right: 20px;
			padding-left: 20px;
			margin: 15px 0px;
			justify-content: space-between;
			font-size: 14px;

			.label {
				color: $lightText;
			}

			.value {
				color: $darkText;
				text-align: right;
			}
		}
	}

	.details-chart-wrapper {
		flex-grow: 1;
		width: calc(100vw - 360px);
	}
}

.highcharts-tooltip > span {
	border-radius: 10px;
	padding: 2px;
}

.highcharts-axis-title {
	font-size: 14px;
	color: $darkText !important;
	fill: $darkText !important;
	text-transform: uppercase;
	font-weight: bold;
}

.highcharts-tick {
	stroke-width: 0;
}

.highcharts-axis-labels > text {
	font-size: 14px !important;
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
}

.highcharts-plot-line-label {
	font-size: 16px;
	fill: $darkText;
}

.chart-tooltip {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	span.chart-tooltip-header {
		display: flex;
		margin: 0px;
		justify-content: center;
		align-items: center;
	}

	div.chart-tooltip-divider {
		width: 100%;
		border-bottom: 1px solid $tooltipColor;
	}

	span.chart-tooltip-header-title {
		font-size: 16px;
		font-weight: bold;
		display: flex;
		align-items: center;
		color: $tooltipColor;
		margin-top: 8px;
		margin-bottom: 8px;
		margin-left: 16px;
		margin-right: 16px;
	}

	div.chart-tooltip-body {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	span.chart-tooltip-body-text {
		font-size: 14px;
		margin-left: 16px;
		margin-right: 16px;
		margin-top: 8px;
		margin-bottom: 8px;
		color: $tooltipColor;
		display: flex;
		align-items: center;
	}
}
