/* Filters panel */
.filters-panel {
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	background-color: $white;
	display: none;
	flex-direction: column;

	::-webkit-scrollbar {
		-webkit-overflow-scrolling: auto;
	}

	&.active {
		display: flex;
	}

	button.close-filters-button {
		font-size: 18px;
		padding-right: 0px;
		padding-top: 0px;
	}

	@media (max-width: $breakpoint-tablet) {
		&.active {
			position: fixed;
			height: 100vh;
			top: 0px;
			z-index: 1000;
		}
	}

	.filters-panel-block {
		border-top: 1px solid $veryLightGrey;

		&.no-border {
			border: none;
		}

		.filters-panel-block-title {
			color: $darkText;
			font-size: 16px;
			margin-bottom: 8px;
			margin-top: 8px;
		}
	}

	.filters-panel-block:last-of-type {
		border-bottom: none;
	}

	.models-list .platform-title:first-of-type {
		margin-top: 0px;
	}

	.models-list {
		margin-left: 20px;
		color: $lightText;

		.platform-title {
			text-transform: uppercase;
			margin-bottom: 10px;
		}
	}

	.model-search-wrapper {
		margin-bottom: 10px;

		input,
		button,
		div,
		span {
			height: 26px;
			border: none;
			background-color: #f0f0f1 !important;
		}

		.input-group-prepend,
		.input-group-prepend span {
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}

		input {
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}

		button {
			padding: 0px 10px;
		}

		.search-icon {
			color: $grey;
		}
	}
}

/* Table panel */
.table-panel {
	background-color: $background;
	padding-left: 20px;

	button.attributes-toggle-button {
		padding-right: 0px;
		i {
			font-size: 20px;
			color: $darkText;
		}
	}

	.top-capacity {
		color: $blue;
		font-size: 15px;
		font-weight: 600;

		input {
			font-size: 17px;
			height: 36px;
			width: 70px;
			margin: 0px 5px;
			margin-left: 10px;
		}
	}

	.new-existing-toggle {
		button.btn {
			border: 1px solid $blue;
			color: $blue;

			&.active {
				background-color: $blue;
				color: white;
			}
		}
	}

	/* Main table */
	.main-table {
		background-color: $white;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

		&.full-radius {
			border-radius: 20px;
		}

		.table-first-row {
			background-color: $white;
			border-bottom: 1px solid $lightGrey;
			cursor: pointer;
			height: 60px;
			color: $lightText;

			.table-first-row-label {
				font-weight: 600;
				color: $lightText;
			}

			&.existing-first-row {
				background-color: $blue !important;
				color: white !important;
				font-weight: 600;
			}
		}

		.table-column {
			border-right: 1px solid $veryLightGrey;

			&.selected {
				.table-first-row {
					color: $blue;
				}
			}
		}

		.existing-column {
			background-color: $blue !important;
			.attributes-block,
			.attributes-block-title,
			.attributes-block-content,
			.attributes-block-title-value,
			.table-cell {
				background-color: $blue !important;
				color: white !important;
				font-weight: 600;
			}
		}

		.filters-column {
			background-color: $white;
			border-right: 1px solid $lightGrey;

			.filters-column-header {
				background-color: $white;
				height: 60px;
				border-bottom: 1px solid $lightGrey;
				display: flex;
				align-items: center;
			}
		}

		.attributes-block {
			border-bottom: 1px solid $veryLightGrey;

			&.active {
				padding-bottom: 10px;
			}

			&.unfoldable {
				padding-bottom: 0px;
			}

			/* Making sure the table cells are the same heights (important for fixed headers and left column) */
			/* Data cell */
			.table-attribute,
			.table-cell {
				height: 30px;
				margin-bottom: 10px;

				&.added-attribute,
				&.existing-cell {
					height: 20px;
					margin-top: -12px;

					.table-attribute-label {
						font-size: 14px;
					}

					&.existing-cell {
						font-size: 14px;
					}
				}

				.existing-input {
					width: 95px;
					height: 30px;
					margin-right: 8px;
				}
			}

			/* Divider cell */
			.attributes-block-title {
				height: 74px;
				background-color: $white;
			}

			/* Row only displayed when a value is set*/
			.forced-display .table-attribute .icon {
				display: inline !important;
				color: $blue;
			}

			.attributes-block-title {
				text-transform: uppercase;
				color: $darkText;
				font-weight: 600;
				font-size: 16px;

				.category-caret {
					font-size: 22px;
					&.active {
						color: $blue;
					}
				}

				.attributes-block-title-value {
					text-transform: none;
					font-weight: 400;
					font-size: 16px;
					color: $lightText;
					text-align: center;
					width: 100%;
					margin-top: -10px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.attributes-block-content {
				.table-cell {
					font-size: 16px;
					color: $lightText;
					text-align: center;
					padding: 10px;
				}
			}

			.table-attribute {
				.table-attribute-label {
					font-size: 16px;
					color: $lightText;
				}

				.clear-button:disabled {
					color: $lightGrey;
				}

				.min-max-group {
					background-color: $blue;
					border-radius: 20px;
					width: 91px;
					height: 25px;
					border: 1px solid $blue;

					&.disabled {
						background-color: $lightGrey;
						color: $white;
						border: none;
					}

					button {
						color: $white;
						font-size: 0.65em;
						padding: 0px 4px;
						&.active {
							background-color: $white;
							color: $blue;
							border-radius: 20px;
						}

						&:disabled {
							background-color: $lightGrey;
							color: $white;
						}
					}
				}
			}
		}

		/* Column selection */
		.selected {
			background-color: $veryLightBlue;

			.table-first-row .table-first-row-label {
				color: $blue;
			}

			.attributes-block-content .table-cell {
				color: $text;
			}

			.attributes-block-content,
			.attributes-block-title,
			.table-first-row {
				background-color: $veryLightBlue;
			}
		}
	}

	/* Table footer */
	.table-footer,
	.table-header {
		button {
			&.margin-left {
				margin-left: 20px;
			}
		}
	}

	.no-result-text {
		color: $darkText;
		font-size: 16px;
		font-weight: 300;
	}
}

.overlay {
	@media (max-width: $breakpoint-tablet) {
		display: block !important;
	}
}

.describe-existing-modal {
	color: $grey;
}
