/* Customize the label (the container) */
.custom-checkbox-wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $lightText;
	font-size: 16px;
	min-width: 18px;
	min-height: 18px;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.custom-checkbox-checkmark {
		position: absolute;
		top: 2px;
		left: 0;
		height: 18px;
		width: 18px;
		border-radius: 3px;
		background-color: $white;
		border: 2px solid $lightText;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked ~ .custom-checkbox-checkmark {
		background-color: $blue;
		border: none;
	}

	/* Create the custom-checkbox-checkmark/indicator (hidden when not checked) */
	.custom-checkbox-checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the custom-checkbox-checkmark when checked */
	input:checked ~ .custom-checkbox-checkmark:after {
		display: block;
	}

	/* Style the custom-checkbox-checkmark/indicator */
	.custom-checkbox-checkmark:after {
		left: 5px;
		top: 2px;
		width: 7px;
		height: 11px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
