body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	height: 100vh;
	width: 100vw;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

input {
	outline: 0;
}

select {
	outline: 0;
	background-color: $white;
}

// Custom Scrollbar
* {
	::-webkit-scrollbar {
		width: 14px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		width: 10px;
		background: $veryLightGrey;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		background-clip: padding-box;
		border: 4px solid rgba(0, 0, 0, 0);

		&:hover {
			width: 10px;
			background: $lightGrey;
			border-radius: 10px;
			-webkit-border-radius: 10px;
			background-clip: padding-box;
			border: 4px solid rgba(0, 0, 0, 0);
		}
	}

	::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}
	::-webkit-scrollbar-corner {
		background-color: transparent;
	}

	// Firefox
	scrollbar-color: $veryLightGrey transparent;
	scrollbar-width: 8px;
}

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}
}

@page {
	size: auto;
	margin: 20mm;
}
