.popover {
	font-size: 14px;
	line-height: 19px;
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.26);
	-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.26);
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
	max-width: 400px;

	.popover-body {
		padding: 20px;
	}
}
