/* Page Header */
.page-header {
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	z-index: 999;
	.top-right-block {
		background-color: $blue;
		border-bottom-left-radius: 15px;
	}
}

.calculator-link {
	display: flex;
	align-items: center;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	font-size: 20px !important;
	text-decoration: none !important;
	i {
		font-size: 14px;
		margin-right: 8px;
	}
}
