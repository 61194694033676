.counter {
  display: flex;
  flex-direction: column;

  &.disabled {
    opacity: 0.5;
    & > .counter-value {
      color: $grey;
    }

    & > .selector-container * {
      cursor: default;
      border-bottom : 6px solid $grey;
    }
  }

  .counter-value {
    font-weight: bolder;
    color: $blue;
    box-sizing: border-box;
  }

  .selector-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .selector-container.disabled {
    cursor: default;

    & > .selector {
      opacity: 0.5;
      border-bottom : 6px solid $grey;
    }
  }

  .selector {
    display : inline-flex;
    height : 0;
    width : 0;
    border-right : 5px solid transparent;
    border-bottom : 6px solid $blue;
    border-left : 5px solid transparent;
  }

  .selector.increment {
    margin-bottom: 12.5px;
  }

  .selector.decrement {
    margin-top: 12.5px;
    transform: rotate(180deg);
  }
}
