.nav-tabs {
	a {
		color: $text;

		&.nav-link.active {
			color: $blue !important;
			font-weight: bold;
		}
	}
}

.tab-content {
}
