.loading-spinner {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid $blue;
	border-radius: 50%;
	border-top-color: $background;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;

	&.small {
		width: 25px;
		height: 25px;
	}
}

.loading-spinner-center {
	text-align: center;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	display: flex;
	height: 100%;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
