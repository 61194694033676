.select {
  opacity: 1;
  position: relative;
  display: block;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;

  &.disabled {
    opacity: 0.5;
  }

  .button-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 2px;
    color: $blue;
    border: 0;
    border-bottom: 1px solid $blue;
    background-color: transparent;
    white-space: nowrap;
    &:focus {
      outline: 0;
    }
  }

  .dropdown-icon {
    padding-right: 4px;
    padding-left: 4px;
    font-size: 10px;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 200px;
    border: 1px solid $grey;
    min-width: 100px;
    position: absolute;
    background-color: $white;
    width: auto;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
  }

  .option {
    color: $blue;
    font-weight: normal;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      background-color: #1065ea;
      color: $white;
    }
  }
}
