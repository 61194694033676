.modal-dialog {
	margin-top: 17%;

	.modal-header {
		&.no-border {
			border: none;
		}

		padding: 8px 1rem;
		align-items: center;
	}

	.modal-content {
		border: none;
		border-radius: 13px;
	}

	.modal-credit {
		font-size: 12px;
	}

	&.global-settings-modal {
		position: absolute;
		margin-top: 0px;
		top: 70px;
		right: 15px;
		width: 290px;
	}

	&.print-modal {
		width: 70vw;
		max-width: 1400px;
		height: 80vh;
		margin-top: 5%;
	}
}
