// Colors
$background: #f5f5f5;
$grey: #6b6e6f;
$veryLightGrey: rgba(112, 112, 112, 0.15);
$lightGrey: #bbbbbb;
$white: #ffffff;
$darkerWhite: #f3f3f3;
$blue: #00a3ca;
$veryLightBlue: rgb(243, 251, 253);

// Text
$lightText: #71757a;
$text: rgb(107, 110, 111);
$darkText: rgb(78, 80, 82);

// Breakpoints
$breakpoint-tablet: 1200px;

// Charts
$yellow: #fdaf00;
$lightBlue: #5fdccf;
$darkBlue: #00619e;
$tooltipColor: #2f314c;
