button.btn {
	border: none;
	font-size: 16px;
	border-radius: 20px;
	padding: 5px 20px;
	font-weight: 500;

	&.no-padding {
		padding: 0px;
	}

	&.shadow {
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	}

	&.btn-primary {
		background-color: $blue;
		color: $white;
	}

	&.btn-secondary {
		background-color: $white;
		color: $blue;
	}

	&.btn-outline-secondary :hover {
		color: $grey;
	}

	&.btn-link {
		color: $blue;
		font-size: 15px;

		&.light {
			font-weight: 400;
		}

		&.underlined {
			text-decoration: underline;
		}

		&.btn-primary {
			color: $blue;
		}

		&.btn-secondary {
			color: inherit;
		}
	}
}
