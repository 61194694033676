.print-modal {
	.print-section-title {
		color: $blue;
		font-size: 25px;
		margin: 0px;
		font-weight: 800;
		text-align: center;

		&:first-of-type {
			margin-top: 0px;
		}
	}

	.modal-body {
		height: 65vh;
	}

	.tab-content {
		height: 95%;
		overflow: auto;
	}

	.print-modal-title {
		color: $blue;
		margin: 0;
		font-size: 23px;
		font-weight: 600;
	}

	.print-modal-sub-title {
		font-size: 23px;
		margin: 0;
		margin-left: 20px;
		color: $lightText;
	}

	.print-disclaimer {
		color: $lightGrey;
		font-size: 15px;
	}

	.nodes-tab-title {
		color: $blue;
		margin: 20px 20px 0px 20px;
	}

	div.print-modal-top-block {
		padding-top: 5px;

		@media (max-width: 992px) {
			div.top-block-left-block {
				justify-content: center;
			}
		}

		@media (max-width: 576px) {
			div.top-block-left-block {
				flex-direction: column;

				div.top-block-counter {
					margin-bottom: 10px;
				}
			}

			div.top-block-right-block {
				flex-direction: column;
			}
		}

		.top-block-cluster-title {
			padding-left: 25px;
			font-size: 25px;
			font-weight: 500;
			color: $text;
		}

		div.top-block-left-block {
			display: flex;
			padding-left: 35px;
			align-items: center;
			justify-content: space-evenly;

			div.top-block-counter {
				color: $text;
				margin-right: 40px;
				margin-top: 30px;
				margin-bottom: 30px;
				.value {
					font-size: 30px;
					text-align: center;
					line-height: 1;
				}

				.sub-title {
					text-transform: uppercase;
					font-weight: 500;
					text-align: center;
				}
			}
		}

		div.top-block-right-block {
			display: flex;
			justify-content: center;
		}

		div.top-block-pic {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 20px 10px;

			img {
				max-width: 250px;
			}

			.pic-label {
				padding-left: 11px;
				color: $lightText;
			}
		}
	}

	div.print-modal-data-wrapper {
		padding: 25px;

		.data-row {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0px;
			}

			.data-block-title-wrapper {
				display: flex;
				width: 100%;
				margin-bottom: -18px;

				.data-block-title {
					color: $blue;
					font-size: 18px;
					margin-right: 20px;
				}
			}

			.data-block-item {
				padding: 15px;
				border-right: 1px solid $veryLightGrey;

				&:last-child {
					border-right: none;
				}

				.data-block-data {
					display: flex;
					margin: 10px 0px;
					justify-content: space-between;
					font-size: 14px;

					.label {
						color: $lightText;
					}

					.value {
						color: $text;
						text-align: right;
					}
				}
			}
		}
	}

	div.print-modal-charts-wrapper {
		padding: 20px;
		div.print-modal-chart-title-wrapper {
			display: flex;
			margin-bottom: 20px;

			.custom-checkbox-wrapper {
				margin-left: 20px;
			}

			div.print-modal-chart-title {
				color: $blue;
				font-size: 18px;
				margin-right: 20px;
				font-weight: 500;
			}
		}
	}

	.divider {
		border-top: 1px solid $lightGrey;
		flex: 1;
		height: 0px;
		margin: 10px 0px;
	}
}
